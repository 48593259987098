import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "../../App.css";
import { Link } from "react-router-dom";

export default function Footer() {
    const currentYear = new Date().getFullYear();
  return (
    <>
      <Container fluid className="bg-dark py-5 mt-5 footer mb-0">
        <Row className="my-3 px-5 align-items-center justify-content-around">
            <Col md={7}>
            <Form>
              <Row className="align-items-center justify-content-center py-3 footer-form">
                <Col sm={8} className="">
                  <Form.Group className="my-3" controlId="Message">
                    <Form.Control
                      // as="textarea"
                      type="text"
                      placeholder="Your Message *"
                    //   style={{ height: "25vh" }}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={4} className="text-center my-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-pill px-4 fs-5"
                  >
                    Send Message 
                  </Button>
                </Col>
              </Row>
            </Form>
            </Col>
        
            <Col md={3} sm={7} className=" ps-3">
            <h5 className="mb-4">Useful Link</h5>
            <p className="mb-2"><Link to={'/'}>Home</Link></p>
            <p className="mb-2"><Link to={'/about'}>About</Link></p>
            <p className="mb-2"><Link to={'/contact'}>Contact Us</Link></p>
            <p className="mb-2"><Link to={'/privacy-policy'}>Privacy Policy</Link></p>
            <p className="mb-2"><Link to={'/terms-service'}>Terms of Service</Link></p>
            </Col>
            {/* <Col md={3} sm={7}>
            <h5 className="mb-4"><span className="text-dark">.</span></h5>
            <p>Business Consulting</p>
            <p>Human Resource</p>
            <p>Digital Solution</p>
            <p>Strategy and Research</p>
            <p>Business consulting</p>
            <p>Financial Support</p>
            </Col> */}
        </Row>
        <Row className="border-top border-bottom border-3 py-4 align-items-center my-5 text-center justify-content-center position-relative z-3">
          <Col md={3} className="mb-3">
            <img
              src="Images/arj_Logo.png"
              width={220}
              alt=""
            />
          </Col>
          <Col md={6} className="col-10 mb-3">
            <h6 className="fw-semibold">
              &copy; {currentYear} ARJ . All rights reserved. Developed by ARJ
              Technologies .
            </h6>
          </Col>
          <Col md={3} className="mb-3">
          <Col className="d-flex gap-3 align-items-center justify-content-center">
          <Link className="bi-icon"><i className="bi bi-facebook"></i></Link>
          <Link className="bi-icon"><i className="bi bi-instagram"></i></Link>
          <Link className="bi-icon"><i className="bi bi-twitter"></i></Link>
          <Link className="bi-icon"><i className="bi bi-youtube"></i></Link>
          </Col>
          </Col>
          {/* <img src="Images/bg-layered.png" className="w-100 footer-my-bg" alt="" /> */}
        </Row>
      </Container>
    </>
  );
}
