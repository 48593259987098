import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Header from "../main/Header";
import Footer from "../main/Footer";
import ErrorImage from "./404Error.jpg"
import './Error.css'
export default function Error404() {

  return (
    <>
      <Header />
      <Container fluid className="p-0">
        <img src={ErrorImage} style={{ width: "100%" }} alt="" />
      </Container>
      <Footer />
    </>
  );
}
