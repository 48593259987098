import React, { useState, useEffect } from "react";
import { Button, Col, Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Header() {
  const [navbarBg, setNavbarBg] = useState("bg-transparent");
  const [navbarPosition, setNavbarPosition] = useState("position-absolute");
  const [navbarColor, setNavbarColor] = useState("text-white");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= window.innerHeight * 0.6) {
        setNavbarBg("bg-white");
        setNavbarPosition("position-fixed");
        setNavbarColor("text-dark");
      } else {
        setNavbarBg("bg-transparent");
        setNavbarPosition("position-absolute");
        setNavbarColor("text-white");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container fluid className={`${navbarPosition} top-0 p-0 header`}>
        <Navbar expand="lg" className={` fw-normal ${navbarBg}`}>
          <Container>
            <Navbar.Brand href="#home">
              <img
                src="Images/arj_Logo.png"
                width={180}
                alt=""
              />
            </Navbar.Brand>
            <Col className="col-md-3" />
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto justify-content-end gap-2">
                <Link className={`${navbarColor} nav-link`} to={'/'}>Home</Link>
                <Link className={`${navbarColor} nav-link`} to={'/about'}>About Us</Link>
                <Link className={`${navbarColor} nav-link`} to={'/contact'}>Contact Us</Link>
                <Button variant="primary" className="rounded-pill px-4">Let's Begin</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </>
  );
}
