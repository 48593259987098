import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Header from "./main/Header";
import Slider from "react-slick";
import Footer from "./main/Footer";
import { Link } from "react-router-dom";

export default function About() {
  const technologies = [
    {
      title: "HTML",
      image: "html-5_5968267.png",
    },
    {
      title: "CSS",
      image: "css-3_5968242.png",
    },
    {
      title: "Bootstrap",
      image: "bootstrap_5968672.png",
    },
    {
      title: "PhotoShop",
      image: "photoshop_5968520.png",
    },
    {
      title: "Illustrator",
      image: "illustrator_5968472.png",
    },
    {
      title: "JavaScript",
      image: "js_5968292.png",
    },
    {
      title: "React JS",
      image: "atom-symbol_45082.png",
    },
    {
      title: "Android Application",
      image: "android_226770.png",
    },
    {
      title: "IOS Application",
      image: "app-store_5977575.png",
    },
    {
      title: "React Native",
      image: "react_1183672.png",
    },
    {
      title: "PHP",
      image: "web_15457933.png",
    },
    {
      title: "Laravel",
      image: "laravel.995x1024.png",
    },
    {
      title: "Node JS",
      image: "node-js.909x1024.png",
    },
    {
      title: "Java",
      image: "java_919854.png",
    },
    {
      title: "Python",
      image: "python_1822899.png",
    },
    {
      title: "MySql",
      image: "mysql_919836.png",
    },
    {
      title: "Amazon Web Services",
      image: "amazon_13170782.png",
    },
    {
      title: "Git",
      image: "git_4494748.png",
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <>
      <Header />
      <Container fluid className="p-0 image-background" style={{backgroundImage:"url('Images/background-image.jpg')"}}>
     <Row className="text-center text-white">
      <h1 className="display-3 fw-bold">About US</h1>
      <h6><Link className="fs-4 me-2 text-white text-decoration-none" to={'/'}>Home</Link><span>/</span> <Link to={'/About'}>About Us</Link></h6>
     </Row>
      </Container>
      <Container fluid className="my-5">
        <Row className="my-5 pt-5">
          {/* <Col md={6} className="mb-3 p-3">
           <img src="Images\33756670_8104938.jpg" className="w-100" alt="" />
          </Col> */}
          <Col md={6} className="mb-5 p-3 position-relative about-div-h">
            <img src="Images/bg-about.jpg" className="about-back-img" alt="" />
            <img
              src="https://arjtechnologies.com/assets/img/pictures/about-2-1.jpg"
              className="about-img-1"
              alt=""
            />
            <img
              src="https://arjtechnologies.com/assets/img/pictures/about-2-2.jpg"
              className="about-img-2"
              alt=""
            />
            <img
              src="https://arjtechnologies.com/assets/img/pictures/about-2-3.jpg"
              className="about-img-3"
              alt=""
            />
            <img
              src="https://arjtechnologies.com/assets/img/pictures/about-2-4.jpg"
              className="about-img-4"
              alt=""
            />
          </Col>
          <Col md={6} className="mb-3 px-4 position-relative z-n1">
            <img
              src="Images/shape-15.png"
              className="about-position-img"
              alt=""
            />
            <h5>ABOUT US</h5>
            <h1 className="display-4 fw-semibold">
              We provide the best IT Services
            </h1>
            <p className="fs-5">
              At <span className="fw-semibold">ARJ TECHNOLOGIES</span> , we
              specialize in delivering unparalleled IT services that empower
              businesses to thrive in the digital age. Our comprehensive
              offerings include custom software development, web and mobile app
              development, web and graphic design, hosting & domain services,
              systems management, cloud solutions, cybersecurity, and IT
              consulting. Committed to excellence and innovation, our dedicated
              team ensures every solution is tailored to meet clients' unique
              needs, enhancing efficiency, security, and overall business
              performance. Discover how we can transform your IT infrastructure
              with our reliable, cutting-edge solutions and drive your success
              forward.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-5">
        <Row className="my-5 justify-content-center">
          <Col md={6} className="text-center">
            <h5>OUR TRAINING INSTITUTE</h5>
            <h1 className="display-5 fw-semibold">
              ARJ Institute of Technology
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card className="card-institute">
              <Card.Body className="p-5">
                <img
                  src="https://arjtechnologies.com/assets//img/icon/icon-quote.png"
                  width={100}
                  className="mb-3"
                  alt=""
                />
                <Card.Text className="my-2 text-secondary fs-4 fw-normal">
                  ARJ TECHNOLOGIES provides IT training programs in software
                  development, web and mobile app development, graphic design,
                  systems management, cybersecurity, cloud solutions, and IT
                  consulting, focusing on hands- on learning to prepare
                  individuals for tech careers.
                </Card.Text>
                <hr />
                <Card.Title className="my-2 d-flex gap-3 align-items-center">
                  <i className="bi bi-geo-alt text-primary"></i>
                  <span>
                    MahaLaxmi Tower, Dholi Mandi Road, Thana-Mod, Chomu , Jaipur
                    , Rajasthan 303702
                  </span>
                </Card.Title>
                <Card.Title className="my-2 d-flex gap-3 align-items-center">
                  <i className="bi bi-telephone text-primary"></i>
                  <span className="text-secondary">+91 7733095696,</span>
                  <span className="text-secondary">+91 9928815696</span>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="card-institute">
              <Card.Body className="p-5">
                <img
                  src="https://arjtechnologies.com/assets//img/icon/icon-quote.png"
                  width={100}
                  className="mb-3"
                  alt=""
                />
                <Card.Text className="my-2 text-secondary fs-4 fw-normal">
                  ARJ TECHNOLOGIES offers IT training programs covering software
                  development, web and mobile app development, cybersecurity,
                  cloud solutions, and IT consulting. With a focus on hands-on
                  learning and expert instruction, we prepare individuals for
                  successful careers in the tech industry.
                </Card.Text>
                <hr />
                <Card.Title className=" d-flex gap-3 align-items-center">
                  <i className="bi bi-geo-alt text-primary"></i>
                  <span>
                    Front Of Cooperative Bank, Near By Girls College, Main
                    Market Ajeetgarh, Rajasthan 332701
                  </span>
                </Card.Title>
                <Card.Title className=" d-flex gap-3 align-items-center">
                  <i className="bi bi-telephone text-primary"></i>
                  <span className="text-secondary">+91 9216395696,</span>
                  <span className="text-secondary">+91 7733095696,</span>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-5">
        <Row className="align-items-center justify-content-center">
          <Col className="col-9 text-center my-3">
            <h1 className="display-5 fw-semibold">Our Latest Technologies</h1>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center text-center my-4">
          {technologies.map((technology, index) => (
            <Col
              lg={2}
              md={3}
              sm={4}
              className="col-6 card-technologies"
              key={index}
            >
              <Card
                className=" mb-3 py-2 px-4"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
              >
                <Card.Body>
                  <Card.Img
                    variant="top"
                    className="w-100"
                    src={`Images/tech/${technology.image}`}
                  />
                  {hoveredIndex === index && (
                    <div
                      className="hover-details"
                      style={{
                        position: "fixed",
                        top: mousePosition.y + 10,
                        left: mousePosition.x + 10,
                      }}
                    >
                      <p>{technology.title}</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}
