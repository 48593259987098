import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Header from "./main/Header";
import Slider from "react-slick";
import Footer from "./main/Footer";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <Container
        fluid
        className="p-0 image-background"
        style={{ backgroundImage: "url('Images/background-image.jpg')" }}
      >
        <Row className="text-center text-white">
          <h1 className="display-4 fw-bold">Privacy Policy</h1>
          <h6>
            <Link
              className="fs-4 me-2 text-white text-decoration-none"
              to={"/"}
            >
              Home
            </Link>
            <span>/</span> <Link to={"/privacy-policy"}>Privacy Policy</Link>
          </h6>
        </Row>
      </Container>
      <Container fluid className="my-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="col-9 text-center my-3 mb-5">
              <h3 className=" fw-semibold">Privacy Policy</h3>
              <p className="">
                At <span className="fw-semibold"> ARJ Technologies</span>, we
                respect your privacy and are committed to protecting the
                personal information you share with us. This Privacy Policy
                outlines how we collect, use, disclose, and safeguard your
                information.
              </p>
            </Col>
          </Row>

          <Row className="terms">
            <Col className="col-12 mb-3">
              <h5>Information We Collect</h5>
              <p>
                <span className="fw-semibold"> Personal Information:</span> We
                may collect personal information such as your name, email
                address, phone number, and payment information when you register
                for our services, contact us, or make a purchase.
              </p>
              <p>
                <span className="fw-semibold"> Usage Data:</span>
                We collect information about how you interact with our website
                and services, including IP addresses, browser type, pages
                visited, and the time and date of access. Cookies and Tracking
                Technologies: Our website may use cookies and similar
                technologies to enhance user experience, analyze usage, and
                deliver targeted advertisements.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>How We Use Your Information</h5>
              <p>
                <span className="fw-semibold">To Provide Services:</span>We use
                your personal information to deliver the services you have
                requested, including IT services and educational courses.
              </p>
              <p>
                <span className="fw-semibold">Communication: </span>
                We may use your information to contact you about updates,
                promotions, or changes to our services. Improvement and
                Development: We analyze usage data to improve our website,
                services, and customer experience. Legal Compliance: We may use
                your information to comply with applicable laws, regulations, or
                legal obligations.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>Information Sharing and Disclosure</h5>
              <p>
                <span className="fw-semibold">
                  Third-Party Service Providers:
                </span>
                We may share your information with trusted third-party service
                providers who assist us in operating our business and delivering
                services. Legal Requirements: We may disclose your information
                if required by law or in response to valid requests by public
                authorities.
              </p>
              <p>
                <span className="fw-semibold">Business Transfers:</span>
                In the event of a merger, acquisition, or sale of assets, your
                information may be transferred as part of that business
                transaction.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>Data Security</h5>
              <p>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, use, or
                disclosure. However, no method of transmission over the Internet
                or electronic storage is 100% secure.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>Your Rights</h5>
              <p>
                <span className="fw-semibold">Access and Correction:</span>You
                have the right to access and update your personal information.
                Opt-Out: You can opt out of receiving marketing communications
                from us by following the unsubscribe instructions provided in
                the emails.
              </p>
              <p>
                <span className="fw-semibold">Data Deletion:</span>You may
                request the deletion of your personal information, subject to
                legal and contractual obligations.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>Changes to This Privacy Policy</h5>
              <p>
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated effective date.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>Contact Us</h5>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at:
              </p>
              <p>
                <span className="fw-semibold">Email:</span>{" "}
                arjtechnologies@gmail.com
              </p>
              <p>
                <span className="fw-semibold">Address:</span>Chomu, Jaipur,
                Rajasthan 303702
              </p>
              <p>
                <span className="fw-semibold">Phone:</span>+917733095696
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
