import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Header from "./main/Header";
import Slider from "react-slick";
import Footer from "./main/Footer";

export default function Index() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  };
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInput = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const services = [
    {
      title: "Software Development",
      image: "icon-3d-11.webp",
      details: "",
    },
    {
      title: "Web Development",
      image: "icon-3d-4.webp",
      details: "",
    },
    {
      title: "Mobile App Development",
      image: "icon-3d-5.webp",
      details: "",
    },
    {
      title: "Web Design Service",
      image: "desgin1.png",
      details: "",
    },
    {
      title: "UI/UX Design Service",
      image: "icon-3d-3.webp",
      details: "",
    },
    {
      title: "Graphic Design",
      image: "graphic.png",
      details: "",
    },
    {
      title: "Hosting & Domain Service",
      image: "security.png",
      details: "",
    },
    {
      title: "Systems Management",
      image: "server.png",
      details: "",
    },
  ];
  const projects = [
    {
      title: "ECommerce Management",
      image: "icon-3d-11.webp",
      details: "",
    },
    {
      title: "School Management",
      image: "icon-3d-4.webp",
      details: "",
    },
    {
      title: "Logistics Management",
      image: "icon-3d-5.webp",
      details: "",
    },
    {
      title: "Financial management",
      image: "desgin1.png",
      details: "",
    },
    {
      title: "Business management",
      image: "icon-3d-3.webp",
      details: "",
    },
  ];

  const technologies = [
    {
      title: "HTML",
      image: "html-5_5968267.png",
    },
    {
      title: "CSS",
      image: "css-3_5968242.png",
    },
    {
      title: "Bootstrap",
      image: "bootstrap_5968672.png",
    },
    {
      title: "PhotoShop",
      image: "photoshop_5968520.png",
    },
    {
      title: "Illustrator",
      image: "illustrator_5968472.png",
    },
    {
      title: "JavaScript",
      image: "js_5968292.png",
    },
    {
      title: "React JS",
      image: "atom-symbol_45082.png",
    },
    {
      title: "Android Application",
      image: "android_226770.png",
    },
    {
      title: "IOS Application",
      image: "app-store_5977575.png",
    },
    {
      title: "React Native",
      image: "react_1183672.png",
    },
    {
      title: "PHP",
      image: "web_15457933.png",
    },
    {
      title: "Laravel",
      image: "laravel.995x1024.png",
    },
    {
      title: "Node JS",
      image: "node-js.909x1024.png",
    },
    {
      title: "Java",
      image: "java_919854.png",
    },
    {
      title: "Python",
      image: "python_1822899.png",
    },
    {
      title: "MySql",
      image: "mysql_919836.png",
    },
    {
      title: "Amazon Web Services",
      image: "amazon_13170782.png",
    },
    {
      title: "Git",
      image: "git_4494748.png",
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <>
      <Header />
      <Container fluid className="p-0">
        <img src="Images/banner.jpeg" style={{ width: "100%" }} alt="" />
      </Container>
      <Container fluid className="mt-5 mb-3">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="col-9 text-center my-3">
              <h3 className="fw-semibold">Our Services</h3>
              <p className="">
                We help businesses with all their technology needs. We build
                custom software, manage cloud services, improve security, and
                give IT advice. Our goal is to make businesses run smoother and
                safer so they can innovate and grow.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center text-center my-4">
            {services.map((service, index) => (
              <Col lg={3} md={4} sm={6} key={index}>
                <Card className="card-service mb-3">
                  <Card.Body>
                    <Card.Img
                      variant="top"
                      className="px-3"
                      src={`Images/${service.image}`}
                    />
                    <img
                      src="Images/box-shape-3.png"
                      className="card-service-position"
                      alt=""
                    />
                    <Card.Title className="my-2">{service.title}</Card.Title>
                    <Button
                      variant="primary"
                      className="rounded-pill px-4 my-2"
                    >
                      Read More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
      <Container fluid className="my-5">
        <Container>
          <Row className="my-5 pt-5">
            <Col md={6} className="mb-5 p-3 position-relative about-div-h">
              <img
                src="Images/bg-about.jpg"
                className="about-back-img"
                alt=""
              />
              <img
                src="https://arjtechnologies.com/assets/img/pictures/about-2-1.jpg"
                className="about-img-1"
                alt=""
              />
              <img
                src="https://arjtechnologies.com/assets/img/pictures/about-2-2.jpg"
                className="about-img-2"
                alt=""
              />
              <img
                src="https://arjtechnologies.com/assets/img/pictures/about-2-3.jpg"
                className="about-img-3"
                alt=""
              />
              <img
                src="https://arjtechnologies.com/assets/img/pictures/about-2-4.jpg"
                className="about-img-4"
                alt=""
              />
            </Col>
            <Col md={6} className="mb-3 px-4 position-relative z-n1">
              <img
                src="Images/shape-15.png"
                className="about-position-img"
                alt=""
              />
              <h5>ABOUT US</h5>
              <h3 className="fw-semibold">We provide the best IT Services</h3>
              <p className="">
                At <span className="fw-semibold">ARJ TECHNOLOGIES</span> , we
                specialize in delivering unparalleled IT services that empower
                businesses to thrive in the digital age. Our comprehensive
                offerings include custom software development, web and mobile
                app development, web and graphic design, hosting & domain
                services, systems management, cloud solutions, cybersecurity,
                and IT consulting. Committed to excellence and innovation, our
                dedicated team ensures every solution is tailored to meet
                clients' unique needs, enhancing efficiency, security, and
                overall business performance. Discover how we can transform your
                IT infrastructure with our reliable, cutting-edge solutions and
                drive your success forward.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="my-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="col-9 text-center my-3">
              <h3 className="fw-semibold">Our Projects</h3>
              <p className="">
                <span className="fw-semibold"> ARJ Technologies</span>{" "}
                specializes in delivering innovative project services, including
                custom software development, web and mobile app development, and
                IT consulting. We focus on optimizing your business operations,
                enhancing security, and driving technological innovation with a
                commitment to quality and client satisfaction. Choose us for
                efficient, reliable, and cutting-edge solutions tailored to meet
                your unique business needs.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center position-relative my-5 pb-5 px-5">
            <Slider {...settings} className="mb-4 text-center">
              {projects.map((project, index) => (
                <Col lg={3} md={4} sm={6} key={index}>
                  <Card className="card-products m-2">
                    <Card.Body>
                      <Card.Img
                        variant="top"
                        className="px-3"
                        src={`Images/${project.image}`}
                      />
                      <Card.Title className="mt-3">{project.title}</Card.Title>
                      <Button
                        variant="primary"
                        className="rounded-pill px-4 my-3"
                      >
                        Read More
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Slider>
            <Col className="col-12 text-center">
              <Button variant="primary" className="rounded-pill px-5 mt-4 fs-5">
                Read More <i class="bi bi-chevron-double-right"></i>
              </Button>
            </Col>
            <img src="Images/shape-17.png" className="project-bg" alt="" />
          </Row>
        </Container>
      </Container>
      <Container fluid className="my-5">
        <Container>
          <Row className="mb-5 justify-content-center">
            <Col md={8} className="text-center">
              <h5>OUR TRAINING INSTITUTE</h5>
              <h3 className="fw-semibold">ARJ Institute of Technology</h3>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card className="card-institute">
                <Card.Body className="p-4">
                  <img
                    src="/images/icon-quote.png"
                    width={30}
                    className=""
                    alt=""
                  />
                  <Card.Text className="my-2 text-secondary fw-normal">
                    ARJ TECHNOLOGIES provides IT training programs in software
                    development, web and mobile app development, graphic design,
                    systems management, cybersecurity, cloud solutions, and IT
                    consulting, focusing on hands- on learning to prepare
                    individuals for tech careers.
                  </Card.Text>
                  <hr />
                  <Card.Title className="my-2 d-flex gap-3 align-items-center">
                    <i className="bi bi-geo-alt-fill text-primary"></i>
                    <span>
                      MahaLaxmi Tower, Dholi Mandi Road, Thana-Mod, Chomu ,
                      Jaipur , Rajasthan 303702
                    </span>
                  </Card.Title>
                  <Card.Title className="my-2 d-flex gap-3 align-items-center">
                    <i className="bi bi-telephone-fill text-primary"></i>
                    <span className="text-secondary">+91 7733095696,</span>
                    <span className="text-secondary">+91 9928815696</span>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="card-institute">
                <Card.Body className="p-4">
                  <img
                    src="/images/icon-quote.png"
                    width={30}
                    className=""
                    alt=""
                  />
                  <Card.Text className="my-2 text-secondary fw-normal">
                    ARJ TECHNOLOGIES offers IT training programs covering
                    software development, web and mobile app development,
                    cybersecurity, cloud solutions, and IT consulting. With a
                    focus on hands-on learning and expert instruction, we
                    prepare individuals for successful careers in the tech
                    industry.
                  </Card.Text>
                  <hr />
                  <Card.Title className=" d-flex gap-3 align-items-center">
                    <i className="bi bi-geo-alt-fill text-primary"></i>
                    <span>
                      Front Of Cooperative Bank, Near By Girls College, Main
                      Market Ajeetgarh, Rajasthan 332701
                    </span>
                  </Card.Title>
                  <Card.Title className="d-flex gap-3 align-items-center">
                    <i className="bi bi-telephone-fill text-primary"></i>
                    <span className="text-secondary">+91 9216395696,</span>
                    <span className="text-secondary">+91 7733095696,</span>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="my-5">
        <Container>
        <Row className="my-5 pt-5">
          <Col md={6} className="mb-3 px-4">
            <h3 className="fw-semibold">Why choose us?</h3>
            <p className="">
              Choose <span className="fw-semibold">ARJ TECHNOLOGIES</span> for
              top-notch IT services and expert solutions. We are dedicated to
              quality, client satisfaction, and enhancing security, making us a
              leader in the tech industry.
            </p>
            <Row className="align-items-center justify-content-between ">
              <Col className="col-6">
                <Card className="choose-card">
                  <Card.Body>
                    <Col className="d-flex align-items-center justify-content-between mb-3">
                      <Card.Title>Expert Solutions</Card.Title>
                      <i class="bi bi-cpu-fill display-6 text-primary"></i>
                    </Col>
                    <Card.Img
                      variant="top"
                      className="px-2 position-relative"
                      src="Images/wavy-line.png"
                    />
                    <span className="blue-point" />
                    <Col className="d-flex align-items-center justify-content-between">
                      <Card.Text>
                        Offering top-notch IT services tailored to your needs.
                      </Card.Text>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-6">
                <Card className="choose-card">
                  <Card.Body>
                    <Col className="d-flex align-items-center justify-content-between mb-3">
                      <Card.Title>Client Satisfaction</Card.Title>
                      <i class="bi bi-database-fill display-6 text-primary"></i>
                    </Col>
                    <Card.Img
                      variant="top"
                      className="px-2 position-relative"
                      src="Images/wavy-line.png"
                    />
                    <span className="blue-point" />
                    <Col className="d-flex align-items-center justify-content-between">
                      <Card.Text>
                        Committed to quality and security, ensuring your peace
                        of mind in the tech industry.
                      </Card.Text>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="mb-3 p-5 text-center">
            <img src="Images/security.png" className="w-75" alt="" />
          </Col>
        </Row>
        </Container>
      </Container>
      <Container fluid className="my-5">
        <Container>
        <Row className="align-items-center justify-content-center">
          <Col className="col-9 text-center my-3">
            <h3 className="fw-semibold">Our Latest Technologies</h3>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center text-center my-4">
          {technologies.map((technology, index) => (
            <Col
              lg={2}
              md={3}
              sm={4}
              className="col-6 card-technologies"
              key={index}
            >
              <Card
                className=" mb-3 py-2 px-4"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
              >
                <Card.Body>
                  <Card.Img
                    variant="top"
                    src={`Images/tech/${technology.image}`}
                  />
                  {hoveredIndex === index && (
                    <div
                      className="hover-details"
                      style={{
                        position: "fixed",
                        top: mousePosition.y + 5,
                        left: mousePosition.x + 5,
                      }}
                    >
                      <p>{technology.title}</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </Container>
      </Container>
      <Container fluid className="my-5">
        <Row className="text-center py-5">
          <h5>CONTACT US</h5>
          <h3 className="fw-semibold">
            Lets Talk About Your Project
          </h3>
        </Row>
        <Row className="pb-5 ps-3">
          <Col md={6} className="px-4 pt-5 form-bg">
            <Form>
              <Row className="align-items-center justify-content-center">
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="FullName">
                    <Form.Control
                      type="text"
                      placeholder="Your Full Name *"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="EmailAddress">
                    <Form.Control
                      type="email"
                      placeholder="Your Email Address *"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="PhoneNumber">
                    <Form.Control
                      type="number"
                      placeholder="Your Phone Number *"
                      value={phoneNumber}
                      onInput={handleInput}
                      required
                    />
                    {phoneNumber.length < 10 && phoneNumber.length > 1 && (
                      <Form.Text className="text-danger">
                        Phone number must be at least 10 digits.
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="WebsiteLink">
                    <Form.Control type="text" placeholder="Your Website Link" />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="Subject">
                    <Form.Control type="text" placeholder="Subject" />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="Message">
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Your Message *"
                      style={{ height: "20vh" }}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="text-center my-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-pill px-5 my-2 fs-5"
                  >
                    Send Message <i class="bi bi-chevron-double-right"></i>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={6} className=" address position-relative z-n1">
            <Col className="mb-2 text-center">
              <img
                src="Images/business-professionals.jpg"
                className="rounded-3"
                style={{ width: "300px" }}
                alt=""
              />
            </Col>
            <Col className="d-flex gap-3 align-items-center mb-4">
              <i className="bi bi-geo-alt fs-3 text-primary"></i>
              <Col>
                <h6 className="mb-0">Office Address</h6>
                <p className="fw-semibold text-secondary mb-0 address-p">
                  MahaLaxmi Tower, Dholi Mandi Road, Thana-Mod, Chomu, Jaipur
                  Rajasthan 303702
                </p>
              </Col>
            </Col>
            <Col className="d-flex gap-3 align-items-center mb-4">
              <i className="bi bi-telephone fs-3 text-primary"></i>
              <Col>
                <h6 className="mb-0">Phone Number</h6>
                <p className="fw-semibold text-secondary mb-0">
                  +91 7733095696
                </p>
              </Col>
            </Col>
            <Col className="d-flex gap-3 align-items-center mb-4">
              <i class="bi bi-envelope-at fs-3 text-primary"></i>
              <Col>
                <h6 className="mb-0">Email Address</h6>
                <p className="fw-semibold text-secondary mb-0">
                  arjinstitute@gmail.com
                </p>
              </Col>
            </Col>
            <img src="Images/cn-2.webp" className="contact-img" alt="" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
