import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Header from "./main/Header";
import Slider from "react-slick";
import Footer from "./main/Footer";
import { Link } from "react-router-dom";

export default function Contact() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1080, 
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800, 
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
   
  };
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInput = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const projects = [
    {
      title: "ECommerce Management",
      image: "icon-3d-11.webp",
      details: "",
    },
    {
      title: "School Management",
      image: "icon-3d-4.webp",
      details: "",
    },
    {
      title: "Logistics Management",
      image: "icon-3d-5.webp",
      details: "",
    },
    {
      title: "Financial management",
      image: "desgin1.png",
      details: "",
    },
    {
      title: "Business management",
      image: "icon-3d-3.webp",
      details: "",
    },
  ];


  return (
    <>
      <Header />
      <Container fluid className="p-0 image-background" style={{backgroundImage:"url('Images/background-image.jpg')"}}>
     <Row className="text-center text-white">
      <h1 className="display-3 fw-bold">Contact US</h1>
      <h6><Link className="fs-4 me-2 text-white text-decoration-none" to={'/'}>Home</Link><span>/</span> <Link to={'/Contact'}>Contact Us</Link></h6>
     </Row>
      </Container>
      <Container fluid className="my-5">
        <Row className="align-items-center justify-content-center">
          <Col className="col-9 text-center my-3">
            <h1 className="display-4 fw-semibold">Our Projects</h1>
            <p className="fs-5">
              <span className="fw-semibold"> ARJ Technologies</span> specializes
              in delivering innovative project services, including custom
              software development, web and mobile app development, and IT
              consulting. We focus on optimizing your business operations,
              enhancing security, and driving technological innovation with a
              commitment to quality and client satisfaction. Choose us for
              efficient, reliable, and cutting-edge solutions tailored to meet
              your unique business needs.
            </p>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center position-relative my-5 pb-5 px-5">
          <Slider {...settings} className="mb-4 text-center">
            {projects.map((project, index) => (
              <Col lg={3} md={4} sm={6} key={index}>
                <Card className="card-products m-2">
                  <Card.Body>
                    <Card.Img
                      variant="top"
                      className="px-3"
                      src={`Images/${project.image}`}
                    />
                    <Card.Title className="mt-3">{project.title}</Card.Title>
                    <Button
                      variant="primary"
                      className="rounded-pill px-4 my-3 fs-5"
                    >
                      Read More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Slider>
          <Col className="col-12 text-center">
            <Button variant="primary" className="rounded-pill px-5 my-5 fs-3">
              Read More <i class="bi bi-chevron-double-right"></i>
            </Button>
          </Col>
          <img src="Images/shape-17.png" className="project-bg" alt="" />
        </Row>
      </Container>
      <Container fluid className="my-5">
        <Row className="text-center py-5">
          <h5>CONTACT US</h5>
          <h1 className="display-6 fw-semibold">
            Lets Talk About Your Project
          </h1>
        </Row>
        <Row className="pb-5 ps-3">
          <Col md={6} className="px-4 pt-5 form-bg">
            <Form>
              <Row className="align-items-center justify-content-center">
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="FullName">
                    <Form.Control
                      type="text"
                      placeholder="Your Full Name *"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="EmailAddress">
                    <Form.Control
                      type="email"
                      placeholder="Your Email Address *"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="PhoneNumber">
                    <Form.Control
                      type="number"
                      placeholder="Your Phone Number *"
                      value={phoneNumber}
                      onInput={handleInput}
                      required
                    />
                    {phoneNumber.length < 10 && phoneNumber.length > 1 && (
                      <Form.Text className="text-danger">
                        Phone number must be at least 10 digits.
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="WebsiteLink">
                    <Form.Control type="text" placeholder="Your Website Link" />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="Subject">
                    <Form.Control type="text" placeholder="Subject" />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="Message">
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Your Message *"
                      style={{ height: "25vh" }}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="text-center my-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-pill px-5 my-2 fs-4"
                  >
                    Send Message <i class="bi bi-chevron-double-right"></i>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={6} className="position-relative z-n1">
          <Col className="mb-2 text-center">
          <img
              src="Images/business-professionals.jpg"
              className="rounded-3"
              style={{width :"380px"  }}
              alt=""
            /></Col>
            <Col className="d-flex gap-3 align-items-center mb-4">
              <i className="bi bi-geo-alt display-6 text-primary"></i>
              <Col>
                <h5 className="">Office Address</h5>
                <p className="fw-semibold text-secondary mb-0 address-p">
                  MahaLaxmi Tower, Dholi Mandi Road, Thana-Mod, Chomu, Jaipur
                  Rajasthan 303702
                </p>
              </Col>
            </Col>
            <Col className="d-flex gap-3 align-items-center mb-4">
              <i className="bi bi-telephone display-6 text-primary"></i>
              <Col>
                <h5 className="">Phone Number</h5>
                <p className="fw-semibold text-secondary mb-0">
                  +91 7733095696
                </p>
              </Col>
            </Col>
            <Col className="d-flex gap-3 align-items-center mb-4">
              <i class="bi bi-envelope-at display-6 text-primary"></i>
              <Col>
                <h5 className="">Email Address</h5>
                <p className="fw-semibold text-secondary mb-0">
                  arjinstitute@gmail.com
                </p>
              </Col>
            </Col>
            <img src="Images/cn-2.webp" className="contact-img" alt="" />
          </Col>
        </Row>
      </Container>
      <Container fluid className="my-5">
       <Row>
       <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.7426360447375!2d75.72005467402958!3d27.164386649232366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dab218f50992f%3A0x180e014178bd765!2sARJ%20INSTITUTE%20OF%20TECHNOLOGY!5e0!3m2!1sen!2sin!4v1720590174827!5m2!1sen!2sin"
  height={450}
  style={{ border: 0, width:'100%' }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

       </Row>
      </Container>
      <Footer />
    </>
  );
}
