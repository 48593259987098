import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Header from "./main/Header";
import Slider from "react-slick";
import Footer from "./main/Footer";
import { Link } from "react-router-dom";

export default function TermsService() {
  return (
    <>
      <Header />
      <Container
        fluid
        className="p-0 image-background"
        style={{ backgroundImage: "url('Images/background-image.jpg')" }}
      >
        <Row className="text-center text-white">
          <h1 className="display-4 fw-bold">Terms of Service</h1>
          <h6>
            <Link
              className="fs-4 me-2 text-white text-decoration-none"
              to={"/"}
            >
              Home
            </Link>
            <span>/</span> <Link to={"/terms-service"}>Terms of Service</Link>
          </h6>
        </Row>
      </Container>
      <Container fluid className="my-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="col-9 text-center my-3 mb-5">
              <h3 className=" fw-semibold">Terms of Service</h3>
              <p className="">
              These Terms of Service govern your use of  <span className="fw-semibold"> ARJ Technologies</span> services, including IT services and educational courses. By accessing or using our services, you agree to comply with and be bound by these Terms.
              </p>
            </Col>
          </Row>

          <Row className="terms">
            <Col className="col-12 mb-3">
              <p>
              Acceptance of Terms By using our services, you agree to these Terms, our Privacy Policy, and any other agreements or policies referenced herein. If you do not agree, please do not use our services.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <p>
              Services Provided ARJ Technologies offers various IT services and educational courses. The specific details of each service will be outlined in the respective service agreement or course description.
              </p>
              <p>
                <span className="fw-semibold">Communication: </span>
                We may use your information to contact you about updates,
                promotions, or changes to our services. Improvement and
                Development: We analyze usage data to improve our website,
                services, and customer experience. Legal Compliance: We may use
                your information to comply with applicable laws, regulations, or
                legal obligations.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>User Responsibilities</h5>
              <p>
                <span className="fw-semibold">
                Accurate Information:
                </span>
                You agree to provide accurate, current, and complete information when registering for our services.
              </p>
              <p>
                <span className="fw-semibold">Compliance:</span>
                You agree to use our services in compliance with applicable laws, regulations, and these Terms.
              </p>
              <p>
                <span className="fw-semibold">Prohibited Conduct:</span>
                You agree not to engage in any activity that is harmful, illegal, or violates the rights of others while using our services.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>Payment and Fees</h5>
              <p> <span className="fw-semibold">
              Pricing:
              </span>
              The fees for our services are outlined in the service agreements or course descriptions. All fees are non-refundable unless otherwise specified.
              </p>
              <p> <span className="fw-semibold">
              Payment:
              </span>
              Payment must be made in accordance with the terms set forth at the time of purchase.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <p>
              Intellectual Property All content, trademarks, logos, and intellectual property used in connection with our services are the property of ARJ Technologies or its licensors. You agree not to use, reproduce, or distribute any content without prior written permission.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <p>
              Limitation of Liability To the fullest extent permitted by law, ARJ Technologies shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our services.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <p>
              Termination We reserve the right to suspend or terminate your access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to others or us.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <p>
              Changes to These Terms We may update these Terms from time to time. Any changes will be effective immediately upon posting the updated Terms on our website.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <p>
              Governing Law These Terms shall be governed by and construed in accordance with the laws of India - Rajasthan, without regard to its conflict of law principles.
              </p>
            </Col>
            <Col className="col-12 mb-3">
              <h5>Contact Us</h5>
              <p>
              Contact Information If you have any questions or concerns about these Terms, please contact us at:
              </p>
              <p>
                <span className="fw-semibold">Email:</span>{" "}
                arjtechnologies@gmail.com
              </p>
              <p>
                <span className="fw-semibold">Address:</span>Chomu, Jaipur,
                Rajasthan 303702
              </p>
              <p>
                <span className="fw-semibold">Phone:</span>+917733095696
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
